import one from '../images/restaurant/1.png'
import two from '../images/restaurant/2.png'
import three from '../images/restaurant/3.png'
import four from '../images/restaurant/4.png'
import five from '../images/restaurant/5.png'
import six from '../images/restaurant/6.png'
import seven from '../images/restaurant/7.png'
import eight from '../images/restaurant/8.png'



// Special Items Pics
import SpecialImgOne from '../images/specials/special-1.jpeg';
import SpecialImgTwo from '../images/specials/special-2.jpeg';
import SpecialImgThree from '../images/specials/special-3.jpg';
import SpecialImgFour from '../images/specials/special-4.jpeg';
import SpecialImgFive from '../images/specials/special-5.jpg';
import SpecialImgSix from '../images/specials/special-6.jpeg';

export const galleryData = [
    {image: one, alt: 'Burger'},
    {image: five, alt: 'Burger'},
    {image: two, alt: 'Burger'},
    {image: four, alt: 'Burger'},
    {image: seven, alt: 'Burger'},
    {image: six, alt: 'Burger'},
    {image: three, alt: 'Burger'},
    {image: eight, alt: 'Burger'},
];



export const footerSpecials = [
    {image: SpecialImgOne, alt: 'special-item'},
    {image: SpecialImgTwo, alt: 'special-item'},
    {image: SpecialImgThree, alt: 'special-item'},
    {image: SpecialImgFour, alt: 'special-item'},
    {image: SpecialImgFive, alt: 'special-item'},
    {image: SpecialImgSix, alt: 'special-item'},
];