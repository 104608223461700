import CHICKENROLLOWRAP from '../images/items/CHICKENROLLOWRAP.jpeg'
import Finefillingpitta from '../images/items/Finefillingpitta.jpg'
import MeltedCheeseQuesadilla from '../images/items/MeltedCheeseQuesadilla.jpg'
import ClassicDoubleCheeseBurger from '../images/items/ClassicDoubleCheeseBurger.jpg'
import TOASTEDPULLEDPANINI from '../images/items/TOASTED PULLED PANINI.jpg'
import TOASTEDCHICKENTASKA from '../images/items/TOASTED CHICKEN TASKA.jpg'
import GOURMETSINGLEBURGER from '../images/items/GOURMET SINGLE BURGER.jpg'
import MEXICANBURRITOS from '../images/items/MEXICAN BURRITOS.jpg'
import ZESTYNACHOSBURGER from '../images/items/ZESTY NACHOS BURGER.jpg'
import CHICKENNTOWERBURGER from '../images/items/CH TOWER BURGER.jpg'
import LAMPOUNDER from '../images/items/LAMPOUNDER.jpg'
import TENDERPULLEDCHB from '../images/items/TENDER PULLED.jpg'
import GRILLEDCHICKENBURGER from '../images/items/GRILLED CHICKEN BURGER.jpg'
import BBQCHICKENWRAP from '../images/items/BBQ CHICKEN WRAP.jpg'
import BONELESSCHICKENANDRICE from '../images/items/BONELESS CHICKEN AND RICE.jpg'
import QTRCHICKENRICEBOX from '../images/items/QTR CHICKEN RICE BOX.jpg'
import INDIANVEGGIEBURGER from '../images/items/INDIAN VEGGIE BURGER.jpg'
import INDIANPANEERWRAP from '../images/items/INDIAN PANEER WRAP.jpg'
import ANDAAZPANEER from '../images/items/ANDAAZ-E-PANEER BURGER.jpg'
import Mixsalad from '../images/items/Mix salad.jpg'
import GrilledChickensalad from '../images/items/Grilled Chicken salad.jpg'
import GRILLEDPANEERSALAD from '../images/specials/special-4.jpeg'
import FRIES from '../images/items/FRIES.jpg'
import MASALAFRIES from '../images/items/MASALA FRIES.jpg'
import ONIONRINGS from '../images/items/ONION RINGS.jpg'
import Nuggets from '../images/items/Nuggets.jpg'
import CORN from '../images/items/CORN.jpg'
import Wedges from '../images/items/Wedges.jpg'
import Mozzarellasticks from '../images/items/Mozzarella sticks.jpg'
import CurlyFries from '../images/items/curly Fries.jpg'
import SPICYRICE from '../images/items/SPICY RICE.jpg'
import VBURGERDOUBLE from '../images/items/VBURGERDOUBLE.jpg'
import VBRUGERSINGLE from '../images/items/VBURGERSINGLE.jpg'
import KIDSMEAL from '../images/items/KIDSMEAL.jpg'
import PIRIPIRIQTRCHKN from '../images/items/PIRIPIRIQTRCHICKEN.jpg'
import PIRIPIRIHALFCHKN from '../images/items/PIRIPIRIHALFCHICKEN.jpg'
import PIRIPIRIFULLCHKN from '../images/items/PIRIPIRIFULLCHICKEN.jpg'
import MILDDIP from '../images/items/MILDDIP.jpg'
import HOTDIP from '../images/items/HOTDIP.jpg'
import GARLICDIP from '../images/items/GARLICDIP.jpg'
import DRINKS from '../images/items/DRINKS.jpg'
import ChocolateFudgeCake from '../images/items/CHOCOLATE FUDGE CAKE.jpg'
import LotusBiscoffCheeseCake from '../images/items/LOTUS BISCOFF CHEESE CAKE.jpg'
import OreoCookieBash from '../images/items/OREO COOKIE BASH.jpg'
import ChocolatePeanutButterStack from '../images/items/CHOCOLATE PEANUT BUTTER STACK.jpg'
import Rocksolidbrownie from '../images/items/ROCK SOLID BROWNIE.jpg'
import BenandJerry from '../images/items/BEN AND JERRY.jpg'
import VeggieBurrito from '../images/items/Veggie burrito.jpg'
import VeggieWrap from '../images/items/Veggie wrap.jpg'
import PaneerRice from '../images/items/PaneerRice.jpg'
import SoloPlatter from '../images/items/SOLO PLATTER.jpg'
import FamilyFeasPlatter from '../images/items/FAMILY FEAST PLATTER.jpg'
import Coleslaw from '../images/items/Coleslaw.jpg'
import threestrips from '../images/items/threestrips.jpg'
import fivestrips from '../images/items/fivestrips.jpg'
import tenstrips from '../images/items/tenstrips.jpg'
import fifteenstrips from '../images/items/fifteenstrips.jpg'
import twentystrips from '../images/items/twentystrips.jpg'
import threewings from '../images/items/threewings.jpg'
import fivewings from '../images/items/fivewings.jpg'
import fifteenwings from '../images/items/fifteenwings.jpg'
import tenwings from '../images/items/tenwings.jpg'
import twentywings from '../images/items/twentywings.jpg'
import gourmetdoubleburger from '../images/items/Gourmet double.jpg'
import PartyPlatter from '../images/items/PARTY PLATTERE.png'

export const MrVBurgers = [
    {
        id: 1,
        title: 'MR V BURGER SINGLE BEEF PATTY',
        info: 'Single Beef Patty, layered with burgrill sauce, bbq sauce, lettuce, onions and tomatoes, topped with cheese slice and gherkins in a brioche bun',
        image: VBRUGERSINGLE,
        price: '5.99',
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },
    {
        id: 2,
        title: 'MR V BURGER DOUBLE BEEF PATTY',
        info: 'Two Beef Patties (45g each), layered with burgrill sauce, lettuce, onions, topped with cheese slice and gherkins in a brioche bun',
        image: VBURGERDOUBLE,
        price: '4.99',
        alt: 'MR V BURGER DOUBLE BEEF PATTIE',
        link: 'View More'
    },
];

export const OurSpecialsitemsData = [
    {
        id: 1,
        title: 'CHICKEN ROLLO WRAP',
        info: 'Flame grilled tender chicken strips with mayo, lettuce and tomato salsa, wrapped in tortilla',
        image: CHICKENROLLOWRAP,
        price: '3.49',
        alt: 'CHICKEN ROLLO WRAP',
        link: 'View More'
    },
    {
        id: 2,
        title: 'FINE FILLING PITTA',
        info: 'Tender grilled chicken fillet in a round toasted pitta with fresh lettuce, burger sauce and fresh tomato.',
        image: Finefillingpitta,
        price: '3.99',
        alt: 'FINE FILLING PITTA',
        link: 'View More'
    },
    {
        id: 3,
        title: 'MELTED CHEESE QUESADILLA',
        info: 'Grilled tender chicken strips, with melted cheese, salsa, onion, all folded in a grilled tortilla ',
        image: MeltedCheeseQuesadilla,
        price: '3.99',
        alt: 'MELTED CHEESE QUESADILLA',
        link: 'View More'
    },

    {
        id: 4,
        title: 'CLASSIC DOUBLE CHEESEBURGER',
        info: 'Two Lamb patties with cheese slice, onions and  tomatoes layered with fresh lettuce and burgrill  sauce in a brioche bun',
        image: ClassicDoubleCheeseBurger,
        price: '3.49',
        alt: 'CLASSIC DOUBLE CHEESEBURGER',
        link: 'View More'
    },

    {
        id: 5,
        title: 'TOASTED PULLED PANINI',
        info: 'Pulled chicken in a sauce of yourchoice, with fresh lettuce, jalapenos, onions, BBQ sauce, served in a Panini bread.',
        image: TOASTEDPULLEDPANINI,
        price: '4.99',
        alt: 'TOASTED PULLED PANINI',
        link: 'View More'
    },


    {
        id: 6,
        title: 'TOASTED CHICKEN TASKA',
        info: 'Grilled tender chicken fillet, layered with mayo and sriacha sauce, jalapeno, topped with cheese in Taska bread.',
        image: TOASTEDCHICKENTASKA,
        price: '4.99',
        alt: 'TOASTED CHICKEN TASKA',
        link: 'View More'
    },

    {
        id: 7,
        title: 'GOURMET SINGLE BURGER',
        info: 'Single beef Patty, layered with Burger sauce, lettuce, onions, tomatoes, topped with cheese slice and gherkins in a brioche bun',
        image: GOURMETSINGLEBURGER,
        price: '4.99',
        alt: 'GOURMET SINGLE BURGER',
        link: 'View More'
    },


    {
        id: 8,
        title: 'GOURMET DOUBLE BURGER',
        info: 'Double beef Patty, layered with Burger sauce, lettuce, onions, tomatoes, topped with cheese slice and gherkins in a brioche bun',
        image: gourmetdoubleburger,
        price: '4.99',
        alt: 'GOURMET DOUBLE BURGER',
        link: 'View More'
    },


    {
        id: 9,
        title: 'MEXICAN BURRITOS (Chicken)',
        info: 'Chicken strips, with spicy rice, red beans, sweet corn, chopped onions, wrapped in tortilla.',
        image: MEXICANBURRITOS,
        price: '3.99',
        alt: 'MEXICAN BURRITOS (Chicken)',
        link: 'View More'
    },

    {
        id: 10,
        title: 'ZESTY NACHOS BURGER',
        info: 'Pulled chicken in a sauce of your choice layered with salsa and mayo, lettuce, nachos topped with cheese and jalapenos on a brioche bun',
        image: ZESTYNACHOSBURGER,
        price: '4.99',
        alt: 'ZESTY NACHOS BURGER',
        link: 'View More'
    },

    {
        id: 11,
        title: 'CHICK N TOWER BURGER',
        info: 'Grilled chicken fillet, marinated with pepper and herb, lettuce and burger sauce salsa, hash brown, onion rings topped with cheese slice on a brioche bun',
        image: CHICKENNTOWERBURGER,
        price: '4.99',
        alt: 'CHICK N TOWER BURGER',
        link: 'View More'
    },

    {
        id: 12,
        title: 'LAM 1/4 POUNDER',
        info: 'Single lamb Patty with lettuce, onions and tomatoes, cheese slice, burger sauce and gherkins in a brioche bun',
        image: LAMPOUNDER,
        price: '4.99',
        alt: 'LAM 1/4 POUNDER',
        link: 'View More'
    },

    {
        id: 13,
        title: 'TENDER PULLED CHICKEN BURGER',
        info: 'Pulled chicken in a sauce of your choice, layered with lettuce, salsa and coleslaw in a brioche bun',
        image: TENDERPULLEDCHB,
        price: '4.99',
        alt: 'TENDER PULLED CHICKEN BURGER',
        link: 'View More'
    },

    {
        id: 14,
        title: 'GRILLED CHICKEN BURGER',
        info: 'Grilled chicken fllet, marinated with pepper & herbs, layered with fresh lettuce, mayonnaise on a soft bun',
        image: GRILLEDCHICKENBURGER,
        price: '3.99',
        alt: 'GRILLED CHICKEN BURGER',
        link: 'View More'
    },

    {
        id: 15,
        title: 'BBQ CHICKEN WRAP',
        info: 'BBQ grilled strips wrapped in tortilla with fresh lettuce, burger sauce and BBQ salsa',
        image: BBQCHICKENWRAP,
        price: '3.49',
        alt: 'BBQ CHICKEN WRAP',
        link: 'View More'
    },

    {
        id: 16,
        title: 'BONELESS CHICKEN AND RICE ',
        info: 'Tender Grilled chicken pieces (boneless) with spicy rice, salad and. sauce of your choice',
        image: BONELESSCHICKENANDRICE,
        price: '5.99',
        alt: 'BONELESS CHICKEN AND RICE ',
        link: 'View More'
    },

    {
        id: 17,
        title: 'QTR CHICKEN RICE BOX',
        info: 'Quarter Grilled chicken with spicy rice and salad',
        image: QTRCHICKENRICEBOX,
        price: '5.99',
        alt: 'QTR CHICKEN RICE BOX',
        link: 'View More'
    },


];

export const VeggieRangeitemsData = [

    {
        title: 'INDIAN VEGGIE BURGER',
        info: 'Brioche bun with raita adds unique flavour to your veggie burger, mango chutney and serv for a crunchy twist',
        image: INDIANVEGGIEBURGER,
        price: '3.49',
        alt: 'INDIAN VEGGIE BURGER',
        link: 'View More'
    },

    {
        title: 'INDIAN VEGGIE WRAP',
        info: 'Premium quality veggie patty with mayonnaise lettuce and salsa, wrapped in tortilla',
        image: VeggieWrap,
        price: '3.49',
        alt: 'INDIAN VEGGIE WRAP',
        link: 'View More'
    },
    {
        title: 'INDIAN PANEER WRAP',
        info: 'Fresh grilled paneer strips, served with mayonnaise lettuce, salsa, wrapped in tortilla',
        image: INDIANPANEERWRAP,
        price: '3.49',
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },
    {
        title: 'MEXICAN BURRITOS (Veggie) ',
        info: 'Veggie patty with spicy rice, red beans, sweet corn, chopped onions, wrapped in tortilla',
        image: VeggieBurrito,
        price: '3.99',
        alt: 'MEXICAN BURRITOS (Veggie) ',
        link: 'View More'
    },
    {
        title: 'ANDAAZ-E-PANEER BURGER',
        info: 'Fresh grilled paneer strips served with coleslaw and lettuce in a soft brioche bun',
        image: ANDAAZPANEER,
        price: '3.99',
        alt: 'ANDAAZ E PANEER BURGER',
        link: 'View More'
    },
    {
        title: 'PANEER RICE',
        info: 'Fresh grilled paneer strips, flavoured with sauce of your choice, served with spicy rice and salad',
        image: PaneerRice,
        price: '5.99',
        alt: 'PANEER RICE',
        link: 'View More'
    },
];

export const TheSaladsitemsData = [
    {
        title: 'MIX SALAD',
        info: 'Lettuce, capsicum, green olives, red onion, sweet corn, grated carrot, tomato and cucumber',
        image: Mixsalad,
        price: '2.99',
        alt: 'MIX SALAD',
        link: 'View More'
    },
    {
        title: 'GRILLED PANEER SALAD',
        info: 'Lettuce, capsicum, green olives, red onion, sweet corn, grated carrot, tomato, cucumber and Paneer cubes with the piri sauce of your choice',
        image: GRILLEDPANEERSALAD,
        price: '4.49',
        alt: 'GRILLED PANEER SALAD',
        link: 'View More'
    },
    {
        title: 'GRILLED CHICKEN SALAD',
        info: 'Lettuce, capsicum, green olives, red onion, sweet corn, grated carrot, tomato, cucumber and Chicken cubes with the piri sauce of your choice',
        image: GrilledChickensalad,
        price: '4.49',
        alt: 'GRILLED CHICKEN SALAD',
        link: 'View More'
    },
];

export const SidesDrinksitemsData = [
    {
        title: 'FRIES (REGULAR) ',
        image: FRIES,
        price: '1.99',
    },
    {
        title: 'FRIES (LARGE)',
        image: FRIES,
        price: '2.49'
    },
    {
        title: 'Masala Fries (LARGE)',
        image: MASALAFRIES,
        price: '2.99'
    },
    {
        title: 'Masala Fries (REGULAR)',
        image: MASALAFRIES,
        price: '2.49'
    },
    {
        title: 'Onion Ring',
        image: ONIONRINGS,
        price: '1.99'
    },
    {
        title: '4x nugget',
        image: Nuggets,
        price: '1.99'
    },
    {
        title: 'Corn on the cob',
        image: CORN,
        price: '2.49'
    },
    {
        title: 'Wedges',
        image: Wedges,
        price: '2.99'
    },
    {
        title: 'Mozzarella sticks (FOR 3)',
        image: Mozzarellasticks,
        price: '1.99'
    },
    {
        title: 'curly Fries',
        image: CurlyFries,
        price: '2.99'
    },
    {
        title: 'spicy Rice',
        image: SPICYRICE,
        price: '2.99'
    },
    {
        title: '3 Piri Wings ',
        image: threewings,
        price: '1.99'
    },
    {
        title: '3 Strips',
        image: threestrips,
        price: '2.95'
    },
    {
        title: 'Coleslaw',
        image: Coleslaw,
        price: '2.49'
    },
    {
        title: 'Mild Dip',
        image: MILDDIP,
        price: '0.30'
    },
    {
        title: 'Hot Dip',
        image: HOTDIP,
        price: '0.30'
    },
    {
        title: 'Garlic Dip',
        image: GARLICDIP,
        price: '0.30'
    },
    {
        title: 'Pepsi, Tango, 7up, Diet Pepsi, Fruit Shoot, Still Bottled Water',
        image: DRINKS,
        price: '1.29'
    },


];

export const PiriPiriitemsData = [

    {
        title: 'Flame Grilled Piri Piri Quarter Chicken',
        image: PIRIPIRIQTRCHKN,
        price: '3.49',
    },
    {
        title: 'Flame Grilled Piri Piri Half Chicken',
        image: PIRIPIRIHALFCHKN,
        price: '5.99',
    },
    {
        title: 'Flame Grilled Piri Piri Full Chicken',
        image: PIRIPIRIFULLCHKN,
        price: '9.99'
    },
    {
        title: 'piri piri Wings 3 Pc',
        image: threewings,
        price: '1.99',
    },
    {
        title: 'piri piri Wings 5 Pc',
        image: fivewings,
        price: '2.99',
    },
    {
        title: 'piri piri Wings 10 Pc',
        image: tenwings,
        price: '5.99',
    },
    {
        title: 'piri piri Wings 15 Pc',
        image: fifteenwings,
        price: '8.99',
    },
    {
        title: 'piri piri Wings 20 Pc',
        image: twentywings,
        price: '11.49',

    },
    {
        title: 'Piri Piri Strips 3 Pc',
        image: threestrips,
        price: '2.99',
    },
    {
        title: 'Piri Piri Strips 5 Pc',
        image: fivestrips,
        price: '3.99',
    },
    {
        title: 'Piri Piri Strips 10 Pc',
        image: tenstrips,
        price: '7.49',
    },
    {
        title: 'Piri Piri Strips 15 Pc',
        image: fifteenstrips,
        price: '10.99',
    },
    {
        title: 'Piri Piri Strips 20 Pc',
        image: twentystrips,
        price: '13.99',
    },

];

export const PlattersitemsData = [
    {
        title: 'Solo Platter serves 2',
        info: 'Half chicken, 4 grilled wings and two large fries',
        image: SoloPlatter,
        price: '9.99',
        alt: 'Solo Platter serves 2',
        link: 'View More'
    },
    {
        title: 'Family Feast Platter serves 4',
        info: 'One whole chicken, 5 grilled wings, 5 grilled strips, 1 spicy rice and 1large fries 1 drink',
        image: FamilyFeasPlatter,
        price: '19.99',
        alt: 'Family Feast Platter serves 4',
        link: 'View More'
    },
    {
        title: 'Party Celebration Platter serves 6-8',
        info: '2 whole chicken, 8 piri wings, 8 piri strips, 2 large fries 2 drinks and 2 spicy rice',
        image: PartyPlatter,
        price: '29.99',
        alt: 'Party Celebration Platter serves 6-8',
        link: 'View More'
    },
];

export const DessertsitemsData = [
    { title: 'Chocolate Fudge Cake', image: ChocolateFudgeCake, price: '3.49' },
    { title: 'Lotus Biscoff Cheese Cake', image: LotusBiscoffCheeseCake, price: '3.49' },
    { title: 'Oreo Cookie Bash', image: OreoCookieBash, price: '4.99 ' },
    { title: 'Chocolate Peanut Butter Stack', image: ChocolatePeanutButterStack, price: '4.99' },
    { title: 'Rock solid brownie', image: Rocksolidbrownie, price: '4.49' },
    { title: 'Ben N Jerry Ice Cream',  image: BenandJerry, size: '100ml', size2: '500ml', price: '1.99', price2: '£ 4.99' },
];

export const KidsitemsData = [

    {
        price: '4.99',
        image: KIDSMEAL
    },







];
