import './App.css';
import { Routes, Route } from "react-router-dom";
import FloatingWhatsapp from "./components/FloatingWhatsapp";
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import Location from './components/Location';
import Menu from './components/Menu';
import Footer from './components/Footer';
import Api from './components/Api'
import {Cart} from './cart/Cart';
import { ShopContextProvider } from "./context/shop-context";


function App() {
  return (
    <div className="App">
      <ShopContextProvider>
      <Navbar />
      <FloatingWhatsapp />
      <Routes>
        <Route path='' exact element={<Home />} />
        <Route path='location' exact element={<Location />} />
        <Route path='menu' exact element={<Menu />} />
        <Route path='Api' exact element={<Api />} />
        <Route path='gallery' exact element={<Gallery />} />
        <Route path='about' element={<About />} />
        <Route path='contact' element={<Contact />} />
        <Route path='cart' element={<Cart />} />
      </Routes>
      <Footer />
      </ShopContextProvider>
    </div>
  );
}

export default App;
