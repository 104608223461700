import React, { useContext,useEffect } from "react";
import { ShopContext } from "../context/shop-context";
import Aos from "aos";
import "aos/dist/aos.css";
import styled from 'styled-components';

const ItemTitle = styled.h4`
padding: 5px;
  font-size: 0.9rem;
  text-align: left;
  font-weight: bold;
  font-family: 'MigraBold';
  // color: #fff;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ItemPrice = styled.h4`
  padding-right: 15px;
  padding-top: 5px;
  font-size: 0.8rem;
  float: right;
  font-weight: bold;
  // color: #fff;
`;

export const Product = (props) => {
  const { id, title, image, price, alt } = props.data;
  const { addToCart, cartItems } = useContext(ShopContext);

  const cartItemCount = cartItems[id];

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
      <div className="product">
        <div className='card-item'>
          <div className="row">
            <div className="col-md-4 col-12">
             <img src={image} alt={alt}/>
            </div>
            <div className="col-md-8 col-12 d-flex flex-row align-items-center">
              <div className='p-2'>
                <div className="description">
                  <p>
                    <ItemTitle>{title}</ItemTitle>
                  </p>
                  <ItemPrice> ${price}</ItemPrice>
                </div>
                   <button className="addToCartBttn" onClick={() => addToCart(id)}>
                       Add To Cart {cartItemCount > 0 && <> ({cartItemCount})</>}
                    </button>
                </div>
            </div>
          </div>
        </div>
       </div>
  );
};
