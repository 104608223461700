import React, { useState } from "react";
import styled from 'styled-components/macro';
import { IoMdClose } from 'react-icons/io';
import { HiOutlineBars3BottomRight } from 'react-icons/hi2';
// import { Link } from 'react-router-dom';
// import { menuData } from '../data/MenuData';
// import { LuShoppingCart } from "react-icons/lu";

import './global.css';
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import { keyframes } from "styled-components";


const gradient = keyframes`
{
0% {
  background-position: 0 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0 50%;
}}
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 30px;
  // background-color: #222;
  animation: ${gradient} 5s ease-in-out infinite;
  background: linear-gradient(to right, #bf953f 0%, #fcf6ba 3.4%, #b38728 100%, #fbf5b7 50%,#aa771c 100%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: 95%;
  height: 80px;
  border-radius: 15px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 99;
`;

const Logo = styled.h5`
  color: #fff;
  font-family: 'MigraLight';
  justify-self: start;
  cursor: pointer;
`;

const LogoResponsive = styled.h5`
  color: #fff;
  font-family: 'MigraLight';
  justify-self: start;
  cursor: pointer;
`;

const NavMenu = styled.ul``;

const NavMenuLink = styled(Link)`
text-decoration: none;
color: #fbf5b7;
padding: 0.7rem 1rem;
white-space: nowrap;
font-family: 'MigraBold';

&: hover{
  // background: rgb(80, 80, 238);
  color: #915d00;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 768px) {

  display: block;
  widows: 100%;
  padding: 2rem 0;
  color: #222;

      &:hover{
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(20px);
      }
}

`;


const ResponsiveMenu = styled.div`  
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }

`;

const MenuBars = styled(HiOutlineBars3BottomRight)`
  color: #fff;
`;

const CloseIcon = styled(IoMdClose)`
  color: #fff;
`;

const Navbar = () => {

  // Responsive Menu Class
  const [menuClick, setMenuClick] = useState(false);
  const handleClick = () => {
    if (menuClick !== true) {
      setMenuClick(true);
    } else {
      setMenuClick(false);
    }
  }

  // scroll to bottom
  // const scrollToBottom = () => {
  //   scroll.scrollToBottom();
  //   setMenuClick(false);
  // }

  const scrollToTop = () => {
    scroll.scrollToTop();
    setMenuClick(false);
  }

  const scrollToMenu = () => {
    scroll.scrollTo(720);
    setMenuClick(false);
  }

  const scrollToAbout = () => {
    scroll.scrollToTop();
    setMenuClick(false);
  }

  const scrollToContact = () => {
    scroll.scrollTo(11200);
    setMenuClick(false);
  }

  const scrollToGallery = () => {
    setMenuClick(false);
    if (window.screen.width < 768) {
      scroll.scrollTo(1750);
    } else {
      scroll.scrollTo(10250);
    }   
  }


  return (
    <Nav>
      <LogoResponsive to='contact' className="responsive-logo">
        <Link style={{ color: "#fff", textDecoration: "none", fontSize: "16px" }} to='contact' onClick={scrollToTop}>
        41 High St, Hounslow TW3 1RH
        </Link>
      </LogoResponsive>

      <Logo className="logo-text">
        <Link style={{ color: "#915d00", textDecoration: "none" }} to='contact' onClick={scrollToTop}>
        41 High St, Hounslow TW3 1RH
        </Link>
      </Logo>

      <ResponsiveMenu>
        <i onClick={handleClick}>
          {menuClick ? <CloseIcon /> : <MenuBars />}
        </i>
      </ResponsiveMenu>
      <NavMenu className={menuClick ? 'nav-menu active' : 'nav-menu'}>
        {/* {menuData.map((item, index) => (
          <NavMenuLink key={index} to={item.link}>{item.title}</NavMenuLink>
        ))} */}

        <NavMenuLink className="nav-link-primary" onClick={scrollToTop} to=''>Home</NavMenuLink>
        {/* <NavMenuLink onClick={scrollToMenu} to=''>Menu</NavMenuLink> */}
        <NavMenuLink onClick={scrollToMenu} to='Api'>Menu</NavMenuLink>
        <NavMenuLink onClick={scrollToAbout} to='about'>About</NavMenuLink>
        <NavMenuLink onClick={scrollToGallery} to=''>Gallery</NavMenuLink>        
        <NavMenuLink onClick={scrollToContact} to=''>Contact</NavMenuLink>
        {/* <NavMenuLink onClick={scrollToContact} to='cart'><LuShoppingCart /></NavMenuLink> */}
      </NavMenu>
    </Nav>
  )
}

export default Navbar;