import React, { useEffect } from 'react'
import Falaknuma from '../images/gallery/falaknuma.jpeg';
import Charminar from '../images/gallery/charminar.jpeg';
import TajMahal from '../images/gallery/taj.jpeg';
import '../App.css';
import Aos from "aos";
import "aos/dist/aos.css";


const About = () => {

  useEffect(() => {
    Aos.init({ 
      offset: 200,
      duration: 2000,
      easing: 'ease-in-sine',
      delay: 100,
     });
  }, []);

  return (
    <div className='about-section'>
      <div className="container">
        <div className="my-5 py-3 row">
          <div className="col-md-5" data-aos="fade-up">
            <img src={Falaknuma} alt="about-img" className='img-fluid' />
          </div>
          <div className="col-md-6">
            <h4 className='about-title'>Taj Falaknuma palace</h4>
            <div className='about-text'>
              <p>
              Nizam was the title of the native sovereigns of Hyderabad State, India, since 1719. They belonged to the Asaf Jahi dynasty. The dynasty was founded by Mir Qamar-ud-Din Siddiqi, a viceroy of the Deccan under the Mughal emperors from 1713 to 1721.
              </p>
              {/* <p>
              Hyderabadi cuisine had become a princely legacy of the Nizams of Hyderabad as it began to further develop under their patronage. The Hyderabadi cuisine is an amalgamation of South Asian, Mughalai, Turkic, and Arabic along with the influence of cuisines of common people of Golconda Sultanate.</p> */}


              {/* <p>The Nizams is a business that has been created by food lovers for food lovers. From our unique branding to vibrant decor we strive to ensure the entire experience is the best every time.</p> */}
              {/* <p>In a nutshell our aim is to become famous as a company that serves delicious, high-quality and value for money</p> */}

            </div>
          </div>
        </div>

        <div className="my-5 py-3 row">

          <div className="col-md-6">
            <h4 className='about-title'>Charminar</h4>
            <div className='about-text'>
              {/* <p>
              Nizam was the title of the native sovereigns of Hyderabad State, India, since 1719. They belonged to the Asaf Jahi dynasty. The dynasty was founded by Mir Qamar-ud-Din Siddiqi, a viceroy of the Deccan under the Mughal emperors from 1713 to 1721.
              </p> */}
              <p>
              Hyderabadi cuisine had become a princely legacy of the Nizams of Hyderabad as it began to further develop under their patronage. The Hyderabadi cuisine is an amalgamation of South Asian, Mughalai, Turkic, and Arabic along with the influence of cuisines of common people of Golconda Sultanate.</p>


              {/* <p>The Nizams is a business that has been created by food lovers for food lovers. From our unique branding to vibrant decor we strive to ensure the entire experience is the best every time.</p> */}
              {/* <p>In a nutshell our aim is to become famous as a company that serves delicious, high-quality and value for money</p> */}

            </div>
          </div>
          <div className="col-md-5" data-aos="fade-up">
            <img src={Charminar} alt="about-img" className='img-fluid' />
          </div>
        </div>

        <div className="my-5 py-3 row">
          <div className="col-md-5" data-aos="fade-up">
            <img src={TajMahal} alt="about-img" className='img-fluid' />
          </div>
          <div className="col-md-6">
            <h4 className='about-title'>Taj Mahal palace</h4>
            <div className='about-text'>
              {/* <p>
              Nizam was the title of the native sovereigns of Hyderabad State, India, since 1719. They belonged to the Asaf Jahi dynasty. The dynasty was founded by Mir Qamar-ud-Din Siddiqi, a viceroy of the Deccan under the Mughal emperors from 1713 to 1721.
              </p> */}
              {/* <p>
              Hyderabadi cuisine had become a princely legacy of the Nizams of Hyderabad as it began to further develop under their patronage. The Hyderabadi cuisine is an amalgamation of South Asian, Mughalai, Turkic, and Arabic along with the influence of cuisines of common people of Golconda Sultanate.</p> */}


              <p>The Nizams is a business that has been created by food lovers for food lovers. From our unique branding to vibrant decor we strive to ensure the entire experience is the best every time.</p>
              {/* <p>In a nutshell our aim is to become famous as a company that serves delicious, high-quality and value for money</p> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About