import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import SidesImg from "../images/Menu/Sides.png";
// import StarterImg from '../images/Menu/a-visually-appealing-and-mouth-watering-3d-illustr-7OlUacm5Qi2BOEmXQOPxjw-lwFnU5q7RRK_ztTTWQValA.png';
import BurgerImg from '../images/Menu/Burger.png';
import ShahiTawaImg from '../images/Menu/ShahiTawa.png'
import RiceImg from '../images/Menu/Rice.png';
import BiryaniImg from '../images/Menu/Biryani.png';
import VegCurriesImg from '../images/Menu/VegCurries.png';
import DessertsImg from '../images/Menu/Desserts.png';
import DrinksImg from '../images/Menu/Drinks.png';
import MilkShakeImg from '../images/Menu/MilkShake.png';
import NaanBreadImg from '../images/Menu/NaanBread.png';
import PizzaImg from '../images/Menu/Pizza.png';
import SaladImg from '../images/Menu/Salad.png';
import VeggieAndVeganImg from '../images/Menu/VeggieAndVegan.png';
import WrapsImg from '../images/Menu/Wraps.png';
import SmoothiesImg from '../images/Menu/Smoothies.png';
import NonVegCurriesImg from '../images/Menu/NonVegCurries.png';
import KidsMenuImg from '../images/Menu/KidsMenu.png';
import ExtraToppingsImg from '../images/Menu/ExtraToppings.png';

const ItemTitle = styled.h5`
  font-size: 1.1em;
  color: #333;
`;

const AddBtn = styled.button`
  background-color: #bd973d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #aa771c;
    font-size: 1.0em;
  }
`;

function Api() {
  const [menu, setMenu] = useState({});

  const fetchMenu = async () => {
    try {
      const responses = await Promise.all([
        axios.get("https://nizamsapi.netlify.app/.netlify/functions/api/"),
      ]);
      setMenu(responses[0].data);
      console.log(responses[0].data); // Check the fetched data structure
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  };

  useEffect(() => {
    fetchMenu();
  }, []);

  const renderMenuSection = (items, imgSrc, heading) => (
    <div className="col-md-4">
      <div className="card mt-5">
        <div className="img-part">
          <img src={imgSrc} alt={heading} className="img-fluid" />
        </div>
        <h4 className="section-heading pt-3">{heading}</h4>
        {Array.isArray(items) ? (
          items.map((item) => (
            <div key={item.id} className="row p-2">
              <div className="d-flex justify-content-between">
                <div>
                  <ItemTitle>{item.title}</ItemTitle>
                </div>
                <div className="d-flex justify-content-start">
                  <AddBtn>
                    £ {item.price ||
                      ` ${item.mdPrice ? ` ${item.mdPrice}` : ""}${
                        item.lgPrice ? ` , £ ${item.lgPrice}` : ""
                      }`}
                  </AddBtn>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No {heading.toLowerCase()} available</p>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="container pt-5 mt-5">
        <div className="m-5">
          <h1 className="text-center">Menu</h1>
        </div>
        <div className="row">
          {/* Starters */}
          {renderMenuSection(menu.starters, SidesImg, "Starters")}
          
          {/* Biryani */}
          {renderMenuSection(menu.Biryani, BiryaniImg, "Biryani")}
          
          {/* Pizza */}
          {renderMenuSection(menu.Pizza, PizzaImg, "Pizza")}

          {/* Shahi Tawa */}
          {renderMenuSection(menu.ShahiTawa, ShahiTawaImg, "Shahi Tawa")}

          {/* Rice */}
          {renderMenuSection(menu.rice, RiceImg, "Rice")}

          {/* Veg Curries */}
          {renderMenuSection(menu.vegCurries, VegCurriesImg, "Veg Curries")}

          {/* Non Veg Curries */}
          {renderMenuSection(menu.NonVegCurries, NonVegCurriesImg, "Non Veg Curries")}

          {/* Wraps */}
          {renderMenuSection(menu.Wraps, WrapsImg, "Wraps")}

          {/* Salad */}
          {renderMenuSection(menu.Salad, SaladImg, "Salad")}

          {/* Desserts */}
          {renderMenuSection(menu.Desserts, DessertsImg, "Desserts")}

          {/* Drinks */}
          {renderMenuSection(menu.Drinks, DrinksImg, "Drinks")}

          {/* Milk Shake */}
          {renderMenuSection(menu.MilkShake, MilkShakeImg, "Milk Shake")}

          {/* Smoothies */}
          {renderMenuSection(menu.Smoothies, SmoothiesImg, "Smoothies")}

          {/* Veggie and Vegan */}
          {renderMenuSection(menu.VeggieAndVegan, VeggieAndVeganImg, "Veggie and Vegan")}

          {/* Kids Menu */}
          {renderMenuSection(menu.KidsMenu, KidsMenuImg, "Kids Menu")}

          {/* Extra Toppings */}
          {renderMenuSection(menu.Extratoppings, ExtraToppingsImg, "Extra Toppings")}

          {/* Naan Bread */}
          {renderMenuSection(menu.NaanBread, NaanBreadImg, "Naan Bread")}

          {/* Burger */}
          {renderMenuSection(menu.Burgers, BurgerImg, "Burger")}


        </div>
      </div>
    </>
  );
}

export default Api;
