import React from 'react';
import { Link } from 'react-router-dom';
import FooterLogo from '../images/footer-logo2.png';

import { IoMdArrowRoundUp } from 'react-icons/io';
import { animateScroll as scroll } from 'react-scroll';
// import styled, { css } from 'styled-components';
import styled from 'styled-components';
import { RiTwitterXFill, RiFacebookFill, RiInstagramLine, RiLinkedinFill, RiMailLine } from 'react-icons/ri';

// import { footerSpecials } from '../data/GalleryData';
import CodeFundaLogo from '../images/cf-nav-logo2.png';

const TwitterIcon = styled(RiTwitterXFill)``;
const FacebookIcon = styled(RiFacebookFill)``;
const InstagramIcon = styled(RiInstagramLine)``;
const LinkedInIcon = styled(RiLinkedinFill)``;
const MailIcon = styled(RiMailLine)``;

const FooterLink = styled(Link)``;

const ArrowUp = styled(IoMdArrowRoundUp)`
  margin-left: 0.5rem;
`;

const Footer = () => {
    const curYear = new Date().getFullYear();

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    return (
        <div className='footer'>
            <div className='footer-content'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-sm-6 col-12">
                            <div className="footer-logo-image">
                                <img src={FooterLogo} alt="footer-logo-img" className='img-fluid footer-logo' />
                            </div>
                            <div className="social-icons">
                                <FooterLink to='https://twitter.com/i/flow/login'><FacebookIcon /></FooterLink>
                                <FooterLink to='https://twitter.com/i/flow/login'><InstagramIcon /></FooterLink>
                                <FooterLink to='https://twitter.com/i/flow/login'><TwitterIcon /></FooterLink>
                                <FooterLink to='mailto:info@amencarers.co.uk'><MailIcon /></FooterLink>
                                <FooterLink to='https://twitter.com/i/flow/login'><LinkedInIcon /></FooterLink>

                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-6">
                                    <h6 className='footer-sub-heads'>Navigation Links</h6>
                                    <div className='footer-links pt-4'>
                                        <div>
                                            <FooterLink to='/' onClick={scrollToTop} className='footer-link-tag'>Home<ArrowUp /></FooterLink>
                                        </div>
                                        <div>
                                            <FooterLink to='/' onClick={scrollToTop} className='footer-link-tag' href="">Menu</FooterLink>
                                        </div>
                                        <div>
                                            <FooterLink to='/' onClick={scrollToTop} className='footer-link-tag' href="">Blog</FooterLink>
                                        </div>
                                        <div>
                                            <FooterLink to='/' onClick={scrollToTop} className='footer-link-tag' href="">Contact</FooterLink>
                                        </div>
                                        <div>
                                            <FooterLink to='/' onClick={scrollToTop} className='footer-link-tag' href="">Testimonal</FooterLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <h6 className='footer-sub-heads'>Useful Links</h6>
                                    <div className='footer-links pt-4'>
                                        <div>
                                            <FooterLink className='footer-link-tag' to="">Policy</FooterLink>
                                        </div>
                                        <div>
                                            <FooterLink className='footer-link-tag' to="">Terms &amp; Conditions</FooterLink>
                                        </div>
                                        <div>
                                            <FooterLink className='footer-link-tag' to="">Location</FooterLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                            <h4 className='footer-sub-heads'>Our Specials</h4>
                            <div>
                                <div className="footer-specials pt-4">
                                    <div className="row">
                                        {footerSpecials.map((item, index) => (
                                            <div className="col-md-4 col-sm-4 col-4 pb-4">
                                                <img src={item.image} className='img-fluid' alt={item.alt} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="footer-margin"></div>
                </div>

                <div className="container-fluid">
                    <div className="footer-line text-center">
                        Copyright ©{curYear} All rights reserved | Crafted by
                        <a target='_blank' rel="noreferrer" href='http://codefunda.co/'>
                            <img src={CodeFundaLogo} alt='CodeFunda.co' height={30} />
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer